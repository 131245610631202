import React, { useState, useEffect } from 'react';
import { array, string, bool } from 'prop-types';
import { navigate } from 'gatsby';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Container, BottomNavigation, BottomNavigationAction, Tooltip,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as svgIcons from '@fortawesome/pro-light-svg-icons';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';

import { footerTheme } from 'theme';
import useStyles, { useToolTipClasses } from './styles';

const getIcon = (icon) => {
  if (icon === 'faApple') {
    return faApple;
  }
  return icon === 'faAndroid' ? faAndroid : svgIcons[icon];
};

const ApplicationFooter = ({
  sticky = false,
  navItems = [],
  currentItem = '',
}) => {
  const styles = useStyles();
  const toolTipClasses = useToolTipClasses();
  const [footerItem, setFooterItem] = useState(currentItem);
  const [footerStyle, setFooterStyle] = useState('darkFooter');

  useEffect(() => {
    setFooterStyle(sticky ? 'stickyWideDarkFooter' : 'darkFooter');
  }, [sticky]);

  return (
    <ThemeProvider theme={footerTheme}>
      <footer className={styles[footerStyle]}>
      <Container className={styles.container}>
          <BottomNavigation
            value={footerItem}
            className={styles.bottomNavContainer}
            onChange={(event, newValue) => {
              if (newValue) {
                setFooterItem(newValue);
              } else {
                setFooterItem(currentItem);
              }
            }}>
          {navItems.map((item, key) => {
            const { icon, route, message } = item;
            return footerItem === message ? (
                <BottomNavigationAction
                  key={key}
                  label={message}
                  value={message}
                  icon={<FontAwesomeIcon icon={getIcon(icon)} size="lg" className={styles.icon}/>}
                  onClick={() => navigate(route)}
                  className={styles.bottomNav}
                  />
            ) : (
                <Tooltip
                  key={key}
                  title={message}
                  aria-label={message}
                  placement="top"
                  classes={toolTipClasses}
                  arrow>
                  <BottomNavigationAction
                    label={message}
                    value={message}
                    icon={<FontAwesomeIcon icon={getIcon(icon)} size="lg" className={styles.icon}/>}
                    onClick={() => navigate(route)}
                    className={styles.bottomNav}
                    />
                </Tooltip>
            );
          })}
          </BottomNavigation>
      </Container>
      </footer>
    </ThemeProvider>
  );
};

ApplicationFooter.propTypes = {
  navItems: array,
  currentItem: string,
  sticky: bool,
};

export default ApplicationFooter;
